import React from 'react';
import PropTypes from 'prop-types';
import { NotFound } from 'cccisd-laravel-boilerplate';
var Nexus = window.cccisd.fortress;
import { withRouter, Redirect } from 'react-router-dom';
var Boilerplate = window.cccisd.boilerplate;
var AppDefs = window.cccisd.appDefs;
import _find from 'lodash/find';
// import LoginForm from 'cccisd-laravel-nexus/es/components/LoginForm';
import { findNav } from 'cccisd-laravel-appdefs';

export default withRouter(
    class AuthRedirect extends React.Component {
        static propTypes = {
            location: PropTypes.object,
            match: PropTypes.object,
            history: PropTypes.object,

            /**
             * This components also handles showing the 404 page.
             * We pass this prop when we know we want to show the 404 page
             */
            isComponentOnly: PropTypes.bool,
        };

        render() {
            let content = 'Redirecting....';

            if (!Nexus.auth()) {
                window.location =
                    this.props.history.location.pathname === '/'
                        ? Boilerplate.url(`/signUp`)
                        : Boilerplate.url(`/signUp${this.props.history.location.pathname}`);
            }

            if (Nexus.auth()) {
                let nav = findNav(AppDefs.navs, AppDefs.routes);
                let homepageRoute = _find(AppDefs.routes, r => r.handle === nav.homepage);
                // if this is not a pass-through display default 404 page
                if (this.props.isComponentOnly) {
                    content = <NotFound />;
                } else if (homepageRoute) {
                    if (homepageRoute.url.includes('https')) {
                        window.location = homepageRoute.url;
                    } else {
                        content = (
                            <Redirect
                                to={{
                                    pathname: homepageRoute.url,
                                }}
                            />
                        );
                    }
                } else {
                    content = (
                        <Redirect
                            to={{
                                pathname: '/',
                            }}
                        />
                    );
                }
            }

            return <div>{content}</div>;
        }
    }
);
